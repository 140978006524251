<template>
    <Page class="feedback-page">
        <template v-slot:title>
            Обратная связь
        </template>
        <div class="feedback-page__content">
            <FormTemplate
                action="Отправить сообщение"
                @submit="submit"
            >
                <fieldset>
                    <Phone
                        title="Телефон"
                        :error="formErrors.phone"
                        v-model="form.phone"
                    />
                    <Field
                        title="E-mail"
                        :error="formErrors.email"
                        v-model="form.email"
                    />
                </fieldset>
                <Field
                    title="Сообщение"
                    v-model="form.message"
                    :error="formErrors.message"
                    textarea
                />
            </FormTemplate>
            <a :href="`mailto:support@kwiza.pw?subject=Отзыв обработки персональных данных ${userId}&body=Телефон: ${form.phone}. ${form.message}`"
                :style="{display: 'none'}" ref="link" target='_blank'></a>
        </div>
    </Page>
</template>

<script>
import getUser from '@/api/getUser';
import Page from '@/applications/personal-area/layouts/page/Page';
import Phone from '@/components/form/field/Phone';
import Validation from "@/ext/validation/Validation";
import validation from "@/mixins/validation";

import './feedback-page.scss';

export default {
    name: 'Feedback',
    mixins: [validation],
    components: {
        FormTemplate: () => import('@/templates/form/Form'),
        Phone,
        Page
    },
    data() {
        return {
            form: {
                phone: "",
                email: "",
                message: ""
            },
            formRules: {
                phone: [Validation.REQUIRED, Validation.PHONE],
                message: [Validation.REQUIRED, Validation.NO_FILTHY]
            },
            userId: 0
        }
    },
    async mounted() {
        const application = await getUser()
        this.userId = application.questionnaire.contactData.id
    },
    methods: {
        async submit() {
            this.validate()
            if (!this.isValid) return;

            this.$refs.link.click();
        }
    }
}
</script>